import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

function Gallery(props) {
    const { isMobile } = props
    return (
        <div style={{height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {/* <div style={{textAlign: 'center', fontSize: 20}}>
                [Under Contruction]
            </div> */}
            <div style={{textAlign: 'center', fontSize: 40, padding: 20}}>
                Gallery
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ImageList cols={isMobile ? 1 : 3} gap={6} sx={{width: isMobile ? '90%' : '50%'}}>
                    {itemData.map((item) => (
                        <ImageListItem key={item.img}>
                        <img
                            src={`${item.img}?w=1280&h=1280&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=1280&h=1280&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                            style={{background: '#a7a7b2'}}
                        />
                        </ImageListItem>
                    ))}
                </ImageList>
            </div>
            <div style={{paddingBottom: isMobile ? '20px' : '105px'}} />
            {/* { isMobile ? null :  />} */}
        </div>
    )
}

const itemData = [
    {
        img: require('../imgs/fun/learnin.jpeg'),
        title: 'Learnin'
    },
    {
        img: require('../imgs/fun/group-pic.jpeg'),
        title: 'Group'
    },
    {
        img: require('../imgs/fun/dancin.jpeg'),
        title: 'Dancin'
    },
    {
        img: 'https://static.wixstatic.com/media/5cdc9c_a054dcd750a2477b82d3dbb3578d0353~mv2.jpg/v1/fill/w_560,h_532,al_c,lg_1,q_80,enc_auto/5cdc9c_a054dcd750a2477b82d3dbb3578d0353~mv2.jpg',
        title: 'Breakfast',
    },
    {
        img: 'https://i0.wp.com/kolami-tx.org/wp/wp-content/uploads/2020/04/7A0CE281-3CE6-44D8-A819-1608D09BFE1E.jpeg?fit=960%2C960&ssl=1&w=640',
        title: 'Burger',
    },
    {
        img: 'https://images.fineartamerica.com/images/artworkimages/mediumlarge/1/minions-minyan-david-zimmerman.jpg',
        title: 'Camera',
    },
    {
        img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlTdMfos2Jt_EXQz31dH_RcsmU7_eevg4M0sbpvF8evJ-0jwQOJsz16Ycsr5yOlvJuYwE&usqp=CAU',
        title: 'Coffee',
    },
    {
        img: 'https://cdn.atlantajewishconnector.com/uploads/2022/05/Minion.jpg'
    },
    {
        img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdkT5VTdL8utyplc1Vs-GJXBt8ZDxSyogVufUClgAoJKGb6gXiRBKmheo3rUQcyCUJKSE&usqp=CAU',
        title: 'Honey',
    },
    // {
    //     img: 'https://media.makeameme.org/created/are-you-jewish-dflc51.jpg',
    //     title: 'Basketball',
    // },
    // {
    //     img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-H9YhdimWppPgzDFmD3XO90mqsOegDnIBqbSKs9mAJGR3hzXbGyGx-EChXVioyvnYOLA&usqp=CAU',
    //     title: 'Fern',
    // },
    // {
    //     img: 'https://i.pinimg.com/originals/10/5f/3a/105f3a666b611a75f1f32779a2af36eb.jpg',
    //     title: 'Mushrooms',
    // },
    {
        img: 'https://i.kym-cdn.com/photos/images/facebook/001/900/248/ee1.jpg',
        title: 'Tomato basil',
    },
    {
        img: 'https://i.kym-cdn.com/photos/images/original/001/962/706/bd7.png',
        title: 'Sea star',
    },
    {
        img: 'https://wojakparadise.net/wojak/281/img',
        title: 'Bike',
    },
];

export default Gallery;