import React, {useState, Component} from 'react'
import { Link } from "react-router-dom";
import ButtonBase from '@mui/material/ButtonBase';
// import 

function Button(props) {
    const { url='', to='/', color='#333333', textColor='#efefff', colorHover='#efefff', textColorHover='#333333', textSize=22, width=200, height=105 } = props;
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };
    const handleMouseOut = () => {
        setIsHovering(false);
    };

    return (url == '') ? (
        <Link style={{
            textAlign: 'center', 
            textDecoration: 'none',
            backgroundColor: isHovering ? colorHover : color, 
            color: isHovering ? textColorHover : textColor,
            fontSize: textSize,
            width: width,
            height: height,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} draggable={false} to={to}>
            {props.children}
        </Link>
    ) : (
        <a style={{
            textAlign: 'center', 
            textDecoration: 'none',
            backgroundColor: isHovering ? colorHover : color, 
            color: isHovering ? textColorHover : textColor,
            fontSize: textSize,
            width: width,
            height: height,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} draggable={false} href={url}>
            {props.children}
        </a>
    )
}

function RedButton(props) {
    return (
        <Button color='#ec2427' textColor='#fff' colorHover='#ff5254' textColorHover='#fff' {...props}>{props.children}</Button>
    )
}

function GreyButton(props) {
    return (
        <Button {...props}>{props.children}</Button>
    )
}

export {Button, RedButton, GreyButton}