import React, {useState} from 'react'
import { Outlet} from "react-router-dom";
import { Button, RedButton, GreyButton } from '../components/Button';

function Navbar(props) {
    const { isMobile } = props;
    return (
        <nav style={{display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    backgroundColor: '#333',
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    width: '100%'}}>
            {['Home', 'Vision', 'Contact', 'Gallery'].map((text, index) => (
                <GreyButton height={105} textSize={22} to={text!='Home'?text.toLowerCase():'/'}>{text}</GreyButton>
            ))}
            <RedButton height={105} textSize={22} url='https://www.jgive.com/new/he/ils/donation-targets/90114'>Donate</RedButton>
            <Outlet />
        </nav> 
    )
}

export default Navbar;
