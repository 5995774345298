import React from 'react'

function Socials() {
    return (
        <div style={{padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{width: '300px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <a href='https://www.twitter.com'><img src={require('../imgs/social/twitter.svg').default}/></a>
                    <a href='https://www.facebook.com'><img src={require('../imgs/social/facebook.svg').default}/></a>
                    <a href='https://www.linkedin.com'><img src={require('../imgs/social/linkedin.svg').default}/></a>
                    <a href='https://www.instagram.com/the_minyan.club/'><img src={require('../imgs/social/instagram.svg').default}/></a>
                    <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'><img src={require('../imgs/social/youtube.svg').default}/></a>
            </div>
        </div>
    )
}

export default Socials;