import React from 'react'
import TextField from '@mui/material/TextField';
import Socials from '../components/Socials'

function Contact(props) {
    const { isMobile } = props
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '90vh'}}>
            <div style={{fontSize: '40px', padding: 10}}>Contact Us!</div>
            <div style={{textAlign: 'center', padding: 20}}>
                Keren HaYesod St 9<br/>
                (Lower ground floor)<br/>
                Jerusalm, Israel<br/><br/>
                info@theminyan.club<br/>
            </div>
            <div style={{width: '250px', padding: 10}}>
                <TextField label="Email" fullWidth={true} maxRows={1}/>
            </div>
            <div style={{width: isMobile ? '300px' : '500px', padding: 10}}>
                <TextField
                label="Message"
                multiline
                fullWidth={true}
                rows={8}
                />
            </div>
            <Socials/>
        </div>
    )
}

export default Contact;