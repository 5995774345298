import React, {useState} from 'react'
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import { Menu } from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import ButtonBase from '@mui/material/ButtonBase';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { Outlet} from "react-router-dom";
import { MyButton, RedButton, GreyButton } from '../components/Button';


export default function SwipeableTemporaryDrawe2r() {
  const [state, setState] = React.useState(false);
  const toggleDrawer = (open) => () => {
    setState(open);
  };

  return (
    <div style={{position: 'fixed',
                 top: '80vh',
                //  bottom: 40,
                 left: 10}}>
      <Fab onClick={toggleDrawer(true)} sx={{bgcolor: '#333'}}>
        <Menu sx={{color: '#fff'}} />
      </Fab>
      {/* <Button onClick={toggleDrawer(true)}><Menu sx={{color: '#333333'}} /></Button> */}
      <SwipeableDrawer
        sx={{color: 'red'}}
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
        anchor='right'
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        
      >
        <Box
          sx={{ bgcolor: '#333333', width: 250, height: '100%' }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            {['Home', 'Vision', 'Contact', 'Gallery'].map((text, index) => (
              <>
                  {/* <div style={{paddingTop: 15}} /> */}
                  <ButtonBase sx={{width: '100%', height: '15%', fontFamily: 'Montserrat'}}>
                    <GreyButton width='100%' height='100%' colorHover='#333' textColorHover='#fff' textSize={18} to={text!='Home'?text.toLowerCase():'/'}>{text.toUpperCase()}</GreyButton>
                  </ButtonBase>
                </>
            ))}
            {/* <div style={{paddingTop: 15}} /> */}
            <ButtonBase sx={{width: '100%', height: '15%', fontFamily: 'Montserrat'}}>
              <RedButton width='100%' height='100%' colorHover='#ec2427' textColorHover='#fff' textSize={18} url='https://www.jgive.com/new/he/ils/donation-targets/90114'>DONATE</RedButton>
            </ButtonBase>
            {/* <Button sx={{bgcolor: '#ec2427', width: '100%', height: '15%', color: 'white', fontFamily: 'Montserrat', fontSize: 18}}>
              <a href='https://www.jgive.com/new/he/ils/donation-targets/90114' style={{textDecoration: 'none', color: 'white'}}>Donate</a>
            </Button> */}
          </div>
        </Box>
        <Outlet />
      </SwipeableDrawer>
    </div>
  );
}