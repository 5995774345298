import React from 'react';
import './App.css';
import { BrowserRouter, Routes ,Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Drawer from './components/Drawer';
import Home from './pages/Home';
import Vision from './pages/Vision';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Donate from './pages/Donate';
import NoPage from './pages/NoPage';

class App extends React.Component {

  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }
  
  componentDidMount = () => {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  // make sure to remove the listener when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  
  render()
  {
    const { width } = this.state;
    const isMobile = width <= 828;

    return (
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home isMobile={isMobile}/>} />
            <Route path="vision" element={<Vision isMobile={isMobile}/>} />
            <Route path="contact" element={<Contact isMobile={isMobile}/>} />
            <Route path="gallery" element={<Gallery isMobile={isMobile}/>} />
            <Route path="donate" element={<Donate isMobile={isMobile}/>} />
            <Route path="*" element={<NoPage isMobile={isMobile}/>} />
          </Route>
        </Routes>
        {isMobile ? <Drawer/> : <Navbar isMobile={isMobile}/>}
      </BrowserRouter>
    );
  }
}

export default App;